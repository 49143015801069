/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@font-face {
  font-family: Ubuntu;
  src: url('/assets/font/ubuntu/Ubuntu-Light.ttf');
}
@font-face {
  font-family: Roboto;
  src: url('/assets/font/roboto/Roboto-Light.ttf')
}

/* replacements for @angular/flex-layout */
.flex {
  flex: 1 1 0%;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-start-center {
  display: flex;
  place-content: center flex-start;
  align-items: center;
}
.flex-center-center {
  display: flex;
  place-content: center center;
  align-items: center;
}
.flex-center-start {
  display: flex;
  place-content: flex-start center;
  align-items: flex-start;
}
.flex-end-start {
  display: flex;
  place-content: center flex-end;
  align-items: center;
}
.flex-start-start {
  display: flex;
  place-content: flex-start;
  align-items: flex-start;
}
.flex-end-end {
  display: flex;
  place-content: flex-end;
  align-items: flex-end;
}

.flex-auto {
  flex: 1 0 auto !important;
}

:root {
  --ion-color-primary: #ED6D05;
  
}

.item-container::part(native) {
  background: none;
}

ion-range::part(knob) {
  background: #76b876;
}
ion-range::part(bar-active) {
  background: #76b876;
}
ion-range::part(pin) {
  background: #76b876;
}
ion-range::part(pin)::before {
  background: #76b876;
}
ion-range::part(bar) {
  background: #fbbcbc;
}
/*
NOTE: I've moved all :host styles to globals. Angular host tag is not working in production for some reason
*/

.bold-alert {
  font-weight: bold;
}

ag-connection-dialog {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
ag-plot-tooltip {
  z-index: 99999;
}
payment-complete-dialog {
  padding: 10px;
  display: flex;
  justify-content: center;
}
ag-sensor-value {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
  flex: 1 1 0%;
}

.center-modal {
  --width: 70%;
  --max-width: 400px;
  --height: 250px;
  --border-radius: 20px;
  --box-shadow: -1px 4px 14px 7px #838383;
}


.ion-page { 
  padding: 0;
  width: 100%;
  height: 100%;
}
.leaflet-control-attribution {
  display: none !important;
}

ion-content {
  font-family: Ubuntu;
  &::part(background) {
    background: url('/assets/images/crop.jpg') center center / cover no-repeat;
  }
}
.selectable {
  user-select: text !important;
}
.button-clear {
  --color: #212121;
}
.show-back-button {
  --color: #212121;
}

.popover-content {
  width: 80vw !important;
  max-width: 500px !important;
}
ion-popover {
  --width: 330px;
}

.card-icon-button {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  z-index: 90;
  margin: 5px;
  >* {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.dialog-content {
  margin-bottom: 10px;
  _border: 2px solid #fff;
  border-radius: 7px;
  margin: 5px;
  padding: 10px;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
  padding-bottom: 100px;
}

// #00B5D8 <-- global select color

.temperature-gradient {
  /* @include background(linear-gradient(0deg, #2980b9, #3498db, #2ecc71, #f1c40f, #d53d0e)); */
  background: linear-gradient(90deg, #2980b9, #3498db, #2ecc71, #f1c40f, #d53d0e);
}
.moisture-gradient {
  background: linear-gradient(90deg, #f1c40f, #87b93a, #27ae60, #28988a, #2980b9);
}

.range-bar {
  background: linear-gradient(90deg, #2980b9, #3498db, #2ecc71, #f1c40f, #d53d0e) !important;
}

.small-button {
  background: rgba(237, 109, 5, 0.8);
  padding: 4px 10px;
  color: #FEFEFE;
  border-radius: 10px;
  font-size: 16px;
  align-self: center;
}

.checkout-toast {
  _margin-right: 80px;
  width: 256px;
  z-index: 20004 !important;
}


.notification-toast {
  //width: 256px;
  top: 60px;
  //left: 50%;
  //transform: translate(-50%);
}

ion-toast::part(container) {
  flex-direction: column !important;
  padding: 5px 0px;
}

.ag-card-header {
  margin: 10px 5px;
  padding-bottom: 0;
  font-weight: 600;
  color: #303c42;
}

.lock-screen {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
}

.ag-card {
 margin-bottom: 10px;
 border-radius: 8px;
 margin: 8px;
 flex: 1 0 auto;
 background-color: rgba(255,255,255,0.9);
 box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
 position: relative;
 overflow: hidden;
 text-overflow: ellipsis;
}
.stats-container {
  padding: 16px;
}


.ag-value-header {
  font-size: 16px; 
  font-weight: bold;
}
.ag-value-section {
  margin-left: 5px;
}
.ag-values-row {
 margin: 12px 0 4px 0;
}


.graph-tooltip {
  background: #4a4a4a;
    padding: 5px;
    opacity: 0.8;
    color: #ececec;
    padding: 5px 15px;
}


body.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
  >ion-header { display: none; }
  ion-header { display: none; }
  >ion-content {
    display: none;
    --background: transparent;
    &::part(background) {
      background: none;
    }
  }
  ion-content {
    display: none;
    --background: transparent;
    &::part(background) {
      background: none;
    }
  }
}
